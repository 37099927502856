import { Button } from "antd";
import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuth } from "../components/context/authContext";
import styles from "../styles/Home.module.scss";
import MyApp from "./_app";

const Home: NextPage = () => {
  const router = useRouter();
  const { state } = useAuth();

  useEffect(() => {
    if (!state.userData?.role && state.authLoading === false) {
      router.replace("/signin");
    }
    if (
      state.userData?.role === "ADMIN" ||
      state.userData?.role === "EMPLOYER" ||
      state.userData?.role === "SUPER_ADMIN"
    ) {
      router.replace("/shifts");
    }
    if (state.userData?.role === "WORKER") {
      router.replace("/grab-shift");
    }
  }, [state.userData, state.authLoading]);

  return <></>;
};

export default Home;
